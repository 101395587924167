/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Metropolis Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Regular'), url('assets/fonts/Metropolis-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis Extra Light';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Extra Light'),
    url('assets/fonts/Metropolis-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis Light';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Light'), url('assets/fonts/Metropolis-Light.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Thin'), url('assets/fonts/Metropolis-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Medium'), url('assets/fonts/Metropolis-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis Semi Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Semi Bold'), url('assets/fonts/Metropolis-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Bold'), url('assets/fonts/Metropolis-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis Extra Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Extra Bold'), url('assets/fonts/Metropolis-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis Black';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Black'), url('assets/fonts/Metropolis-Black.woff') format('woff');
}

.slick-dots li button:before {
  font-size: 0;
  width: 0;
  height: 0;
}

.slick-dots li {
  opacity: 1;
  background: #d4d4d4;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.slick-dots li.slick-active {
  background: var(--chakra-colors-primary-500);
  width: 54px;
  border-radius: 12px;
  transition: all 0.3s ease-out;
}
